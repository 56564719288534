@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */
.path-animation {
    stroke-dasharray: 400; /* Increased value to ensure full path coverage */
    stroke-dashoffset: 1500;
    animation: draw-path 2s ease forwards, fill-path 0.7s ease 2s forwards; /* Adjusted time for longer stroke */
    fill: transparent;
    stroke: black;
    stroke-width: 2px;
}

.text-animation {
    opacity: 0;
    animation: fade-in-text 0.8s ease forwards 1.0s, fade-in-text-2 0.8s ease forwards 1.8s;
    /* animation: fade-in-text-2 0.8s ease forwards 1.8s; */
}

.text-animation-2 {
    opacity: 0;
    animation: fade-in-text 0.8s ease forwards 2s
    /* animation: fade-in-text-2 0.8s ease forwards 1.8s; */
}
@keyframes draw-path {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill-path {
    from {
        fill: transparent;
    }
    to {
        fill: black;
        stroke: transparent;
    }
}

@keyframes fade-in-text {
    to {
        opacity: 1;
    }
}

@keyframes fade-in-text-2 {
    to {
        fill: white;
    }
}
